import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import "../styles/MobileMasterpiecesSection.css";

import nakshatraVeda1_Image from "../images/nakshatraVeda1_Mobile.png";
import nakshatraAaztha_Image from "../images/nakshatraAaztha_Mobile.webp";
import nakshatraGokul_Image from "../images/nakshatraGokul_Mobile.webp";
import nakshatraPrithvi_Image from "../images/nakshatraPrithvi_Mobile.webp";
// import nakshatraAarambh_Image from "../images/nakshatraAarambh_Mobile.webp";
import nakshatraShree_Ram_Nagar_Image from "../images/nakshatraShree_Ram_Nagar_Image.png";
import nakshatraAuris_Image from "../images/nakshatra_Auris_image.png";

// import nakshatraAarambh_Image from "../images/nakshatraResidency_Mobile.png";

import image2 from "../images/nakshatraAuris_Mobile.png";
import image7 from "../images/nakshatraVeda2_Mobile.png";
import image8 from "../images/shreeRamNagar_Mobile.png";

import readMoreIcon from "../images/bottom_arrow.png";
import { useNavigate } from "react-router-dom";

const MobileMasterpiecesSection = () => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  const handleNavigateToProjects = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  return (
    <div className="mobile-masterpiece-section">
      <div className="mobile-masterpiece-section-title">
        {/* MASTERPIECES <br /> IN MOTION */}
        ONGOING <br/> PROJECTS
      </div>
      {/* <div className="mobile-masterpiece-section-subtitle">
        ( MASTERPIECES IN MOTION )
      </div> */}
      <Grid container spacing={3} className="mobile-masterpiece-image-grid">
        <Grid item xs={12} className="mobile-masterpiece-image-container">
          <div
            className="mobile-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-veda-i")}
          >
            <img
              src={nakshatraVeda1_Image}
              alt="nakshatra-veda-i"
              loading="lazy"
              className="mobile-masterpiece-project-image"
            />
            <div className="mobile-masterpiece-image-overlay">
              <div className="mobile-masterpiece-image-title">
                Nakshatra <br /> VEDA I & II
              </div>
              <div className="mobile-masterpiece-image-subtitle">Vasai</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className="mobile-masterpiece-image-container">
          <div
            className="mobile-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-aazstha")}
          >
            <img
              src={nakshatraAaztha_Image}
              alt="nakshatra-aazstha"
              loading="lazy"
              className="mobile-masterpiece-project-image"
            />
            <div className="mobile-masterpiece-image-overlay">
              <div className="mobile-masterpiece-image-title">
                NAKSHATRA <br /> AAZSTHA
              </div>
              <div className="mobile-masterpiece-image-subtitle">Vasai</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className="mobile-masterpiece-image-container">
          <div
            className="mobile-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-prithvi")}
          >
            <img
              src={nakshatraPrithvi_Image}
              alt="nakshatra-prithvi"
              loading="lazy"
              className="mobile-masterpiece-project-image"
            />
            <div className="mobile-masterpiece-image-overlay">
              <div className="mobile-masterpiece-image-title">
                NAKSHATRA <br /> PRITHVI
              </div>
              <div className="mobile-masterpiece-image-subtitle">Vasai</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} className="mobile-masterpiece-image-container">
          <div
            className="mobile-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-gokul")}
          >
            <img
              src={nakshatraGokul_Image}
              alt="nakshatra-gokul"
              loading="lazy"
              className="mobile-masterpiece-project-image"
            />
            <div className="mobile-masterpiece-image-overlay">
              <div className="mobile-masterpiece-image-title">
                NAKSHATRA <br /> GOKUL
              </div>
              <div className="mobile-masterpiece-image-subtitle">Virar</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className="mobile-masterpiece-image-container">
          <div
            className="mobile-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("shree-ram-nagar")}
          >
            <img
              src={nakshatraShree_Ram_Nagar_Image}
              alt="shree-ram-nagar"
              loading="lazy"
              className="mobile-masterpiece-project-image"
            />
            <div className="mobile-masterpiece-image-overlay">
              <div className="mobile-masterpiece-image-title">
                SHREE RAM <br /> NAGAR COMPLEX
              </div>
              <div className="mobile-masterpiece-image-subtitle">Virar</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className="mobile-masterpiece-image-container">
          <div
            className="mobile-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-auris")}
          >
            <img
              src={nakshatraAuris_Image}
              alt="nakshatra-auris"
              loading="lazy"
              className="mobile-masterpiece-project-image"
            />
            <div className="mobile-masterpiece-image-overlay">
              <div className="mobile-masterpiece-image-title">
                NAKSHATRA <br /> AURIS
              </div>
              <div className="mobile-masterpiece-image-subtitle">
                Nala Sopara
              </div>
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={12} className="mobile-masterpiece-image-container">
          <div
            className="mobile-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-aarambh")}
          >
            <img
              src={nakshatraAarambh_Image}
              alt="nakshatra-aarambh"
              loading="lazy"
              className="mobile-masterpiece-project-image"
            />
            <div className="mobile-masterpiece-image-overlay">
              <div className="mobile-masterpiece-image-title">
                NAKSHATRA <br /> AARAMBH
              </div>
              <div className="mobile-masterpiece-image-subtitle">Naigaon</div>
            </div>
          </div>
        </Grid> */}
      </Grid>
      {/* {showMore && (
        <Grid container spacing={3} className="mobile-masterpiece-image-grid">
          <Grid item xs={12} className="mobile-masterpiece-image-container">
            <div className="mobile-masterpiece-image-wrapper">
              <img
                src={nakshatraVeda1_Image}
                alt="Project 6"
                className="mobile-masterpiece-project-image"
              />
              <div className="mobile-masterpiece-image-overlay">
                <div className="mobile-masterpiece-image-title">
                  Nakshatra <br /> VEDA I
                </div>
                <div className="mobile-masterpiece-image-subtitle">Vasai</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="mobile-masterpiece-image-container">
            <div className="mobile-masterpiece-image-wrapper">
              <img
                src={image7}
                alt="Project 6"
                className="mobile-masterpiece-project-image"
              />
              <div className="mobile-masterpiece-image-overlay">
                <div className="mobile-masterpiece-image-title">
                  Nakshatra <br /> VEDA II
                </div>
                <div className="mobile-masterpiece-image-subtitle">Vasai</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="mobile-masterpiece-image-container">
            <div className="mobile-masterpiece-image-wrapper">
              <img
                src={image8}
                alt="Project 6"
                className="mobile-masterpiece-project-image"
              />
              <div className="mobile-masterpiece-image-overlay">
                <div className="mobile-masterpiece-image-title">
                  SHREE RAM NAGAR COMPLEX
                </div>
                <div className="mobile-masterpiece-image-subtitle">Virar</div>
              </div>
            </div>
          </Grid> 
        </Grid>
      )} */}
      {/* <Button
        onClick={handleReadMore}
        className="mobile-masterpiece-read-more-button"
      >
        <img
          src={readMoreIcon}
          alt="Read More"
          className={`mobile-masterpiece-read-more-icon ${
            showMore ? "rotated" : ""
          }`}
        />
      </Button> */}
    </div>
  );
};

export default MobileMasterpiecesSection;
