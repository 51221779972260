import React, { useState } from "react";
import "../../styles/MeetJSBExperts.css";
import buildingImage from "../../images/Building_new_image.webp";
import half_building_image from "../../images/half_building_image.webp";
import MissionIcon from "../../images/mission.webp";
import VisionIcon from "../../images/vision.webp";
import TurningVisionIcon from "../../images/Turning_mission.webp";

const MeetJSBExpertsDesktop = () => {
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <div className="MeetJSBExperts-desktop-main">
      <h2 className="MeetJSBExperts-desktop-title">
        {/* MEET THE <br /> JSB EXPERTS  */}
        MEET OUR <br /> LEADERSHIP TEAM
      </h2>
      <div
        className={`MeetJSBExperts-desktop-container ${
          readMore ? "expanded" : "collapsed"
        }`}
      >
        <div className="MeetJSBExperts-desktop-description">
          <p className="MeetJSBExperts-desktop-description-yellow-text">
            Mr. Sunil D. Seksaria has been consulting in  real estate
            since 1990, known for his strong  entrepreneurial skills and
            perseverance.
          </p>
          <br/>
          {readMore && (
            <>
              {/* <br /> */}
              <p className="MeetJSBExperts-desktop-description-white-text">
                With a history of satisfied clients, he believes in building
                access to every segment of society, from affordable housing to
                high-end luxury spaces. His negotiation skills, market
                knowledge, and strategic approach have made him a trusted
                consultant.
              </p>
              <br />
              <p className="MeetJSBExperts-desktop-description-yellow-text">
                Divyesh Seksaria, a distinguished Business Management
                postgraduate from SPJIMR, serves as the CEO of our group.
              </p>
              <br />
              <p className="MeetJSBExperts-desktop-description-white-text">
                With over 10-15 years of real estate experience and a
                comprehensive understanding of business dynamics, Divyesh takes
                charge of our operations across all verticals. His strategic
                leadership and focus on the company’s vision enable him to guide
                the team cohesively towards achieving our long-term goals. By
                aligning each department’s efforts with our overarching
                objectives, Divyesh ensures that we not only meet but exceed our
                targets.
              </p>
              <br />
              <p className="MeetJSBExperts-desktop-description-yellow-text">
                Jitendra Ranka, who has been a founding member since the
                inception of our company, has worked closely with Mr. Sunil
                Seksaria. Their combined expertise and leadership have been
                vital to the development and achievements of our organization.
              </p>
              <br />

              <div className="MeetJSBExperts-desktop-leadership-message">
                <p className="MeetJSBExperts-desktop-leadership-message-text">
                  In our industry, leadership means having a team dedicated to
                  meeting each customer’s needs. At JSB Group, we recruit top
                  talent to ensure excellent customer service from start to
                  finish. Our team values integrity in every transaction,
                  maintaining ethical standards in all dealings.
                </p>
                <br />
                <br />

                <span
                  onClick={toggleReadMore}
                  className="MeetJSBExperts-read-more-button"
                >
                  Show Less
                </span>
              </div>
            </>
          )}

          {!readMore && (
            <span
              onClick={toggleReadMore}
              className="MeetJSBExperts-read-more-button"
            >
              Read More
            </span>
          )}
        </div>

        <div className="MeetJSBExperts-desktop-image-container">
          <img
            src={readMore ? buildingImage : half_building_image}
            alt="Building"
            className="MeetJSBExperts-desktop-building-image"
          />
        </div>
      </div>

      <div className="MeetJSBExperts-desktop-mission-vision-container">
        <div className="MeetJSBExperts-desktop-mission-vision-card">
          <img
            src={MissionIcon}
            alt="Mission"
            className="MeetJSBExperts-desktop-icon"
          />
          <h3 className="MeetJSBExperts-desktop-card-title">MISSION</h3>
          <p className="MeetJSBExperts-desktop-card-description">
            Creating exceptional living spaces with a dedication to quality that
            transcends the ordinary, making each home a unique expression of
            refined living and forward-thinking design.
          </p>
        </div>
        <div className="MeetJSBExperts-desktop-mission-vision-card">
          <img
            src={VisionIcon}
            alt="Vision"
            className="MeetJSBExperts-desktop-icon"
          />
          <h3 className="MeetJSBExperts-desktop-card-title">VISION</h3>
          <p className="MeetJSBExperts-desktop-card-description">
            To forge a legacy in homebuilding by delivering quality residences
            that embody our commitment and proudly represent our core values.
          </p>
        </div>
        {/* <div className="MeetJSBExperts-desktop-mission-vision-card">
          <img
            src={TurningVisionIcon}
            alt="Turning Vision into Reality"
            className="MeetJSBExperts-desktop-icon"
          />
          <h3 className="MeetJSBExperts-desktop-card-title">
            TURNING VISION <br /> INTO REALITY
          </h3>
          <p className="MeetJSBExperts-desktop-card-description">
            Our project, Nakshatra Tower, Mira Road (E) is among the only few
            projects in and around Mumbai trying to harness solar energy.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default MeetJSBExpertsDesktop;
