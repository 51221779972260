import React, { useState } from "react";
import { Container, Grid, Button } from "@mui/material";
import "../../styles/CompletedProjects.css";

import image1 from "../../images/nakshatra greens phase I.png";
import image2 from "../../images/Nakshatra greens phase ii.png";
import image3 from "../../images/nakshatra primus.png";
import image4 from "../../images/nakshatra pride I.png";
import image5 from "../../images/nakshatra pride II.webp";
import image6 from "../../images/nakshatra ozone.webp";
import image7 from "../../images/sai nakshatra trident.png";
import image8 from "../../images/Ritu paradise.webp";
import image9 from "../../images/Garden court.webp";
import image10 from "../../images/nakshatra tower.webp";
import image11 from "../../images/nakshatraAarambh_Mobile.png";
import nakshatra_residency from "../../images/nakshatra residency.png";

import readMoreIcon from "../../images/bottom_arrow.svg";

const CompletedProjectsMobile = () => {
  const [showMore, setShowMore] = useState(false);

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="mobile-completed-projects-section">
      <div className="mobile-completed-projects-section-title">
        COMPLETED PROJECTS
      </div>
      <Grid
        container
        spacing={3}
        className="mobile-completed-projects-image-grid"
      >
        <Grid
          item
          xs={12}
          className="mobile-completed-projects-image-container"
        >
          <div className="mobile-completed-projects-image-wrapper">
            <img
              src={image1}
              alt="Project 1"
              loading="lazy"
              className="mobile-completed-projects-project-image"
            />
            <div className="mobile-completed-projects-image-overlay">
              <div className="mobile-completed-projects-image-title">
                NAKSHATRA <br />
                GREEN <br />
                PHASE I
              </div>
              <div className="mobile-completed-projects-image-subtitle">
                Naigaon
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className="mobile-completed-projects-image-container"
        >
          <div className="mobile-completed-projects-image-wrapper">
            <img
              src={image2}
              alt="Project 2"
              loading="lazy"
              className="mobile-completed-projects-project-image"
            />
            <div className="mobile-completed-projects-image-overlay">
              <div className="mobile-completed-projects-image-title">
                NAKSHATRA <br />
                GREEN <br />
                PHASE II
              </div>
              <div className="mobile-completed-projects-image-subtitle">
                Naigaon
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className="mobile-completed-projects-image-container"
        >
          <div className="mobile-completed-projects-image-wrapper">
            <img
              src={image3}
              alt="Project 3"
              loading="lazy"
              className="mobile-completed-projects-project-image"
            />
            <div className="mobile-completed-projects-image-overlay">
              <div className="mobile-completed-projects-image-title">
                NAKSHATRA PRIMUS
              </div>
              <div className="mobile-completed-projects-image-subtitle">
                Naigaon
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {showMore && (
        <Grid
          container
          spacing={3}
          className="mobile-completed-projects-image-grid"
        >
          <Grid
            item
            xs={12}
            className="mobile-completed-projects-image-container"
          >
            <div className="mobile-completed-projects-image-wrapper">
              <img
                src={image4}
                alt="Project 4"
                loading="lazy"
                className="mobile-completed-projects-project-image"
              />
              <div className="mobile-completed-projects-image-overlay">
                <div className="mobile-completed-projects-image-title">
                  NAKSHATRA <br />
                  PRIDE I & II
                </div>
                <div className="mobile-completed-projects-image-subtitle">
                  Naigaon
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="mobile-completed-projects-image-container"
          >
            <div className="mobile-completed-projects-image-wrapper">
              <img
                src={image6}
                alt="Project 4"
                loading="lazy"
                className="mobile-completed-projects-project-image"
              />
              <div className="mobile-completed-projects-image-overlay">
                <div className="mobile-completed-projects-image-title">
                  NAKSHATRA <br />
                  OZONE
                </div>
                <div className="mobile-completed-projects-image-subtitle">
                  Naigaon
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className="mobile-completed-projects-image-container"
          >
            <div className="mobile-completed-projects-image-wrapper">
              <img
                src={nakshatra_residency}
                alt="Project 4"
                loading="lazy"
                className="mobile-completed-projects-project-image"
              />
              <div className="mobile-completed-projects-image-overlay">
                <div className="mobile-completed-projects-image-title">
                  NAKSHATRA <br />
                  RESIDENCY
                </div>
                <div className="mobile-completed-projects-image-subtitle">
                  Makane Kapase
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="mobile-completed-projects-image-container"
          >
            <div className="mobile-completed-projects-image-wrapper">
              <img
                src={image8}
                alt="Project 4"
                loading="lazy"
                className="mobile-completed-projects-project-image"
              />
              <div className="mobile-completed-projects-image-overlay">
                <div className="mobile-completed-projects-image-title">
                  RITU <br /> PARADISE
                </div>
                <div className="mobile-completed-projects-image-subtitle">
                  Mira-Bhayander
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="mobile-completed-projects-image-container"
          >
            <div className="mobile-completed-projects-image-wrapper">
              <img
                src={image9}
                alt="Project 4"
                loading="lazy"
                className="mobile-completed-projects-project-image"
              />
              <div className="mobile-completed-projects-image-overlay">
                <div className="mobile-completed-projects-image-title">
                  GARDEN <br /> COURT
                </div>
                <div className="mobile-completed-projects-image-subtitle">
                  Bhayander West
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="mobile-completed-projects-image-container"
          >
            <div className="mobile-completed-projects-image-wrapper">
              <img
                src={image10}
                alt="Project 4"
                loading="lazy"
                className="mobile-completed-projects-project-image"
              />
              <div className="mobile-completed-projects-image-overlay">
                <div className="mobile-completed-projects-image-title">
                  NAKSHATRA <br /> TOWER
                </div>
                <div className="mobile-completed-projects-image-subtitle">
                  Bhayander East
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="mobile-completed-projects-image-container"
          >
            <div className="mobile-completed-projects-image-wrapper">
              <img
                src={image11}
                alt="Project 4"
                loading="lazy"
                className="mobile-completed-projects-project-image"
              />
              <div className="mobile-completed-projects-image-overlay">
                <div className="mobile-completed-projects-image-title">
                  NAKSHATRA <br /> AARAMBH
                </div>
                <div className="mobile-completed-projects-image-subtitle">
                  Naigaon
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="mobile-completed-projects-image-container"
          >
            <div className="mobile-completed-projects-image-wrapper">
              <img
                src={image7}
                alt="Project 4"
                loading="lazy"
                className="mobile-completed-projects-project-image"
              />
              <div className="mobile-completed-projects-image-overlay">
                <div className="mobile-completed-projects-image-title">
                  SAI <br /> NAKSHATRA <br /> TRIEDENT
                </div>
                <div className="mobile-completed-projects-image-subtitle">
                  Virar West
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <Button
        onClick={handleReadMore}
        className="mobile-completed-projects-read-more-button"
      >
        <img
          src={readMoreIcon}
          alt="Read More"
          className={`mobile-completed-projects-read-more-icon ${
            showMore ? "rotated" : ""
          }`}
        />
      </Button>
    </div>
  );
};

export default CompletedProjectsMobile;
