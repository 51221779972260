import React from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/ProjectGallery.css";

// Import images
import Nakshatra_Veda1_Gallery_image1 from "../../images/Nakshatra_Veda1_Gallery_image1.png";
import Nakshatra_Veda1_Gallery_image2 from "../../images/Nakshatra_Veda1_Gallery_image2.png";
import Nakshatra_Veda1_Gallery_image3 from "../../images/Nakshatra_Veda1_Gallery_image3.png";
import Nakshatra_Veda1_Gallery_image4 from "../../images/Nakshatra_Veda1_Gallery_image4.png";
import Nakshatra_Veda1_Gallery_image5 from "../../images/Nakshatra_Veda1_Gallery_image5.png";
import Nakshatra_Veda1_Gallery_image6 from "../../images/Nakshatra_Veda1_Gallery_image6.png";
import Nakshatra_Veda1_Gallery_image7 from "../../images/Nakshatra_Veda1_Gallery_image7.png";
import Nakshatra_Veda1_Gallery_image8 from "../../images/Nakshatra_Veda1_Gallery_image8.png";

import Nakshatra_Aazatha_Gallery_image1 from "../../images/Nakshatra_Aazatha_Gallery_image1.png";
import Nakshatra_Aazatha_Gallery_image2 from "../../images/Nakshatra_Aazatha_Gallery_image2.png";
import Nakshatra_Aazatha_Gallery_image3 from "../../images/Nakshatra_Aazatha_Gallery_image3.png";
import Nakshatra_Aazatha_Gallery_image4 from "../../images/Nakshatra_Aazatha_Gallery_image4.png";
import Nakshatra_Aazatha_Gallery_image5 from "../../images/Nakshatra_Aazatha_Gallery_image5.png";
import Nakshatra_Aazatha_Gallery_image6 from "../../images/Nakshatra_Aazatha_Gallery_image6.png";
import Nakshatra_Aazatha_Gallery_image7 from "../../images/Nakshatra_Aazatha_Gallery_image7.png";
import Nakshatra_Aazatha_Gallery_image8 from "../../images/Nakshatra_Aazatha_Gallery_image8.png";
import Nakshatra_Aazatha_Gallery_image9 from "../../images/Nakshatra_Aazatha_Gallery_image9.png";
import Nakshatra_Aazatha_Gallery_image10 from "../../images/Nakshatra_Aazatha_Gallery_image10.png";
import Nakshatra_Aazatha_Gallery_image11 from "../../images/Nakshatra_Aazatha_Gallery_image11.png";
import Nakshatra_Aazatha_Gallery_image12 from "../../images/Nakshatra_Aazatha_Gallery_image12.png";
import Nakshatra_Aazatha_Gallery_image13 from "../../images/Nakshatra_Aazatha_Gallery_image13.png";

import Nakshatra_Gokul_Gallery_image1 from "../../images/Nakshatra_Gokul_Gallery_image1.png";
import Nakshatra_Gokul_Gallery_image2 from "../../images/Nakshatra_Gokul_Gallery_image2.png";
import Nakshatra_Gokul_Gallery_image3 from "../../images/Nakshatra_Gokul_Gallery_image3.png";
import Nakshatra_Gokul_Gallery_image4 from "../../images/Nakshatra_Gokul_Gallery_image4.png";
import Nakshatra_Gokul_Gallery_image5 from "../../images/Nakshatra_Gokul_Gallery_image5.png";
import Nakshatra_Gokul_Gallery_image6 from "../../images/Nakshatra_Gokul_Gallery_image6.png";
import Nakshatra_Gokul_Gallery_image7 from "../../images/Nakshatra_Gokul_Gallery_image7.png";
import Nakshatra_Gokul_Gallery_image8 from "../../images/Nakshatra_Gokul_Gallery_image8.png";
import Nakshatra_Gokul_Gallery_image9 from "../../images/Nakshatra_Gokul_Gallery_image9.png";

import Nakshatra_Prithvi_Gallery_image1 from "../../images/Nakshatra_Prithvi_Gallery_image1.png";
import Nakshatra_Prithvi_Gallery_image2 from "../../images/Nakshatra_Prithvi_Gallery_image2.png";
import Nakshatra_Prithvi_Gallery_image3 from "../../images/Nakshatra_Prithvi_Gallery_image3.png";
import Nakshatra_Prithvi_Gallery_image4 from "../../images/Nakshatra_Prithvi_Gallery_image4.png";
import Nakshatra_Prithvi_Gallery_image5 from "../../images/Nakshatra_Prithvi_Gallery_image5.png";
import Nakshatra_Prithvi_Gallery_image6 from "../../images/Nakshatra_Prithvi_Gallery_image6.png";
import Nakshatra_Prithvi_Gallery_image7 from "../../images/Nakshatra_Prithvi_Gallery_image7.png";
import Nakshatra_Prithvi_Gallery_image8 from "../../images/Nakshatra_Prithvi_Gallery_image8.png";
import Nakshatra_Prithvi_Gallery_image9 from "../../images/Nakshatra_Prithvi_Gallery_image9.png";
import Nakshatra_Prithvi_Gallery_image10 from "../../images/Nakshatra_Prithvi_Gallery_image10.png";
import Nakshatra_Prithvi_Gallery_image11 from "../../images/Nakshatra_Prithvi_Gallery_image11.png";
import Nakshatra_Prithvi_Gallery_image12 from "../../images/Nakshatra_Prithvi_Gallery_image12.png";

import Nakshatra_Aarambh_Gallery_image1 from "../../images/Nakshatra_Aarambh_Gallery_image1.png";
import Nakshatra_Aarambh_Gallery_image2 from "../../images/Nakshatra_Aarambh_Gallery_image2.png";
import Nakshatra_Aarambh_Gallery_image3 from "../../images/Nakshatra_Aarambh_Gallery_image3.png";

import ShreeRamNagar_Gallery_image1 from '../../images/ShreeRamNagar_Gallery_image1.png'
import ShreeRamNagar_Gallery_image2 from '../../images/ShreeRamNagar_Gallery_image2.png'
import ShreeRamNagar_Gallery_image3 from '../../images/ShreeRamNagar_Gallery_image3.png'
import ShreeRamNagar_Gallery_image4 from '../../images/ShreeRamNagar_Gallery_image4.png'
import ShreeRamNagar_Gallery_image5 from '../../images/ShreeRamNagar_Gallery_image5.png'
import ShreeRamNagar_Gallery_image6 from '../../images/ShreeRamNagar_Gallery_image6.png'
import ShreeRamNagar_Gallery_image7 from '../../images/ShreeRamNagar_Gallery_image7.png'

import Nakshatra_Auris_Gallery_image1 from '../../images/Nakshatra_Auris_Gallery_image1.png'
import Nakshatra_Auris_Gallery_image2 from '../../images/Nakshatra_Auris_Gallery_image2.png'

import leftArrow from "../../images/left-arrow.png";
import rightArrow from "../../images/right-arrow.png";

const ProjectGalleryDesktop = () => {
  const { projectId } = useParams();

  // Define image sets for each project
  const imageSets = {
    "nakshatra-veda-i": [
      Nakshatra_Veda1_Gallery_image1,
      Nakshatra_Veda1_Gallery_image2,
      Nakshatra_Veda1_Gallery_image3,
      Nakshatra_Veda1_Gallery_image4,
      Nakshatra_Veda1_Gallery_image5,
      Nakshatra_Veda1_Gallery_image6,
      Nakshatra_Veda1_Gallery_image7,
      Nakshatra_Veda1_Gallery_image8,
    ],
    "nakshatra-aazstha": [
      Nakshatra_Aazatha_Gallery_image2,
      Nakshatra_Aazatha_Gallery_image1,
      Nakshatra_Aazatha_Gallery_image3,
      Nakshatra_Aazatha_Gallery_image4,
      Nakshatra_Aazatha_Gallery_image5,
      Nakshatra_Aazatha_Gallery_image6,
      Nakshatra_Aazatha_Gallery_image7,
      Nakshatra_Aazatha_Gallery_image8,
      Nakshatra_Aazatha_Gallery_image9,
      Nakshatra_Aazatha_Gallery_image10,
      Nakshatra_Aazatha_Gallery_image11,
      Nakshatra_Aazatha_Gallery_image12,
      Nakshatra_Aazatha_Gallery_image13,
    ],
    "nakshatra-gokul": [
      Nakshatra_Gokul_Gallery_image1,
      Nakshatra_Gokul_Gallery_image2,
      Nakshatra_Gokul_Gallery_image3,
      Nakshatra_Gokul_Gallery_image4,
      Nakshatra_Gokul_Gallery_image5,
      Nakshatra_Gokul_Gallery_image6,
      Nakshatra_Gokul_Gallery_image7,
      Nakshatra_Gokul_Gallery_image8,
      Nakshatra_Gokul_Gallery_image9,
    ],
    "nakshatra-prithvi": [
      Nakshatra_Prithvi_Gallery_image1,
      Nakshatra_Prithvi_Gallery_image2,
      Nakshatra_Prithvi_Gallery_image3,
      Nakshatra_Prithvi_Gallery_image4,
      Nakshatra_Prithvi_Gallery_image5,
      Nakshatra_Prithvi_Gallery_image6,
      Nakshatra_Prithvi_Gallery_image7,
      Nakshatra_Prithvi_Gallery_image8,
      Nakshatra_Prithvi_Gallery_image9,
      Nakshatra_Prithvi_Gallery_image10,
      Nakshatra_Prithvi_Gallery_image11,
      Nakshatra_Prithvi_Gallery_image12,
    ],
    "nakshatra-aarambh": [
      Nakshatra_Aarambh_Gallery_image1,
      Nakshatra_Aarambh_Gallery_image2,
      Nakshatra_Aarambh_Gallery_image3,
    ],
    "shree-ram-nagar":[
      ShreeRamNagar_Gallery_image1,
      ShreeRamNagar_Gallery_image2,
      ShreeRamNagar_Gallery_image3,
      ShreeRamNagar_Gallery_image4,
      ShreeRamNagar_Gallery_image5,
      ShreeRamNagar_Gallery_image6,
      ShreeRamNagar_Gallery_image7
    ],
    "nakshatra-auris":[
      Nakshatra_Auris_Gallery_image1,
      Nakshatra_Auris_Gallery_image2,
    ]
  };

  // Select images based on projectId
  const images = imageSets[projectId] || [];

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "150px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerPadding: "120px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 554,
        settings: {
          centerPadding: "50px",
        },
      },
    ],
  };

  return (
    <div className="ProjectGallery_desktop-slide-container">
      <h2 className="ProjectGallery_desktop-title">PROJECT GALLERY</h2>
      <Slider {...settings} className="ProjectGallery_desktop-swiper">
        {images.length > 0 ? (
          images.map((image, idx) => (
            <div key={idx} className="ProjectGallery_desktop-slide">
              <img
                src={image}
                alt={`slide ${idx}`}
                className="ProjectGallery_desktop-image"
                onError={() => console.error(`Image failed to load: ${image}`)}
              />
            </div>
          ))
        ) : (
          <div>No images available for this project.</div>
        )}
      </Slider>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="ProjectGallery_desktop-arrow ProjectGallery_desktop-right-arrow"
      onClick={onClick}
    >
      <img src={rightArrow} alt="right arrow" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="ProjectGallery_desktop-arrow ProjectGallery_desktop-left-arrow"
      onClick={onClick}
    >
      <img src={leftArrow} alt="left arrow" />
    </div>
  );
};

export default ProjectGalleryDesktop;
