import React, { useRef } from "react";
import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import IntroductionSection from "./components/IntroductionSection";
import InfoCards from "./components/InfoCards";
import CompletedProjects from "./components/CompletedProjects/CompletedProjects";
import { Route, Routes } from "react-router-dom";
import ProjectsPage from "./Pages/ProjectsPage";
import Header from "./components/Header";
import MasterpiecesSection from "./components/MasterpiecesSection";
import ContactUsForm from "./components/ContactUsForm";
import MeetJSBExperts from "./components/MeetJSBExperts/MeetJSBExperts";
import InTheirOwnWords from "./components/InTheirOwnWords/InTheirOwnWords";
import ThankYouPage from "./Pages/ThankYouPage";
import Footer from "./components/Footer";

function App() {
  const homeTopRef = useRef(null);

  const aboutRef = useRef(null);
  const testimonialsRef = useRef(null);
  const contactRef = useRef(null);
  const introductionRef = useRef(null);
  const projectsRef = useRef(null);
  const masterpiecesRef = useRef(null);
  const otherProjectsRef = useRef(null);

  const scrollToSection = (ref, offset = 70) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header
        aboutRef={aboutRef}
        testimonialsRef={testimonialsRef}
        contactRef={contactRef}
        masterpiecesRef={masterpiecesRef}
        homeTopRef={homeTopRef}
        CompletedProjectsRef={projectsRef}
        otherProjectsRef={otherProjectsRef}
        introductionRef={introductionRef}
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HomePage scrollToSection={() => scrollToSection(contactRef)} />
              <IntroductionSection ref={introductionRef} />
              <InfoCards
                scrollToSection={scrollToSection}
                sectionRefs={{
                  projectsRef,
                  masterpiecesRef,
                }}
              />

              <MeetJSBExperts ref={aboutRef} />
              <MasterpiecesSection ref={masterpiecesRef} />

              <CompletedProjects ref={projectsRef} />
              {/* <InTheirOwnWords ref={testimonialsRef} /> */}
              {/* <ContactUsForm ref={contactRef} /> */}
              {/* Footer is rendered here for the main route */}
              <Footer
                scrollToSection={scrollToSection}
                ref={contactRef}
                refs={{
                  introductionRef,
                  testimonialsRef,
                  aboutRef,
                  masterpiecesRef,
                  contactRef,
                }}
              />
            </>
          }
        />
        <Route
          path="/projects/:projectId"
          element={
            <>
              <ProjectsPage otherProjectsRef={otherProjectsRef} />
              <Footer
                scrollToSection={scrollToSection}
                ref={contactRef}
                refs={{
                  introductionRef,
                  testimonialsRef,
                  aboutRef,
                  masterpiecesRef,
                  contactRef,
                }}
              />
            </>
          }
        />

        <Route
          exact
          path="/thank-you"
          element={
            <>
              <div className="blur-background">
                <HomePage />
              </div>
              <ThankYouPage />
              {/* No Footer here */}
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
