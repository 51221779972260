import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/FloorPlansAzastha.css";
import callImage from "../images/callImage.png";

import Aazstha_FloorPlan_image1 from "../images/Aazstha_FloorPlan_image1.png";
import Aazstha_FloorPlan_image2 from "../images/Aazstha_FloorPlan_image2.png";

import Gokul_FloorPlan_image1 from "../images/Gokul_FloorPlan_image1.png";
import Gokul_FloorPlan_image2 from "../images/Gokul_FloorPlan_image2.png";

import Prithvi_FloorPlan_image1 from "../images/Prithvi_FloorPlan_image1.png";
import Prithvi_FloorPlan_image2 from "../images/Prithvi_FloorPlan_image2.png";
import Prithvi_FloorPlan_image3 from "../images/Prithvi_FloorPlan_image3.png";
import Prithvi_FloorPlan_image4 from "../images/Prithvi_FloorPlan_image4.png";
import Prithvi_FloorPlan_image5 from "../images/Prithvi_FloorPlan_image5.png";

import Aarambh_FloorPlan_image1 from "../images/Aarambh_FloorPlan_image1.png";
import Aarambh_FloorPlan_image2 from "../images/Aarambh_FloorPlan_image2.png";

import ShreeRam_FloorPlan_image1 from "../images/ShreeRam_FloorPlan_image1.png";
import ShreeRam_FloorPlan_image2 from "../images/ShreeRam_FloorPlan_image2.png";
import ShreeRam_FloorPlan_image3 from "../images/ShreeRam_FloorPlan_image3.png";
import ShreeRam_FloorPlan_image4 from "../images/ShreeRam_FloorPlan_image4.png";
import ShreeRam_FloorPlan_image5 from "../images/ShreeRam_FloorPlan_image5.png";
import ShreeRam_FloorPlan_image6 from "../images/ShreeRam_FloorPlan_image6.png";

import Auris_FloorPlan_image1 from "../images/Auris_FloorPlan_image1.png";
import Auris_FloorPlan_image2 from "../images/Auris_FloorPlan_image2.png";
import Auris_FloorPlan_image3 from "../images/Auris_FloorPlan_image3.png";
import Auris_FloorPlan_image4 from "../images/Auris_FloorPlan_image4.png";

import Aazstha_QR from "../images/Aazstha_QR.jpeg";
import Gokul_QR from "../images/Gokul_QR.jpeg";
import Prithvi_QR from "../images/Prithvi_QR.jpeg";
import ShreeRam_QR from '../images/ShreeRam_QR.jpeg'

import { useNavigate, useParams } from "react-router-dom";

const FloorPlansAzastha = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    project: "",
    contactNumber: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;

    if (name === "name") {
      newValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 100);
    }

    if (name === "contactNumber") {
      newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    }

    if (name === "email") {
      newValue = value.replace(/[^a-zA-Z0-9._%+-@]/g, "").slice(0, 100);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, project, contactNumber, email } = formData;

    if (!name) newErrors.name = "Name is required.";
    if (!project) newErrors.project = "Project selection is required.";
    if (!contactNumber) newErrors.contactNumber = "Contact number is required.";
    if (!email) newErrors.email = "Email is required.";

    // Validate name (max 100 characters, no special characters or emojis)
    if (name) {
      if (name.length > 100) {
        newErrors.name = "Name must be less than 100 characters.";
      } else if (!/^[a-zA-Z\s]*$/.test(name)) {
        newErrors.name = "Name cannot contain special characters or emojis.";
      }
    }

    // Validate email format
    if (
      email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
    ) {
      newErrors.email = "Email is invalid.";
    }

    // Validate contact number (must be exactly 10 digits)
    if (contactNumber && !/^\d{10}$/.test(contactNumber)) {
      newErrors.contactNumber = "Contact number must be exactly 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Log form data to check the values
    console.log(formData);

    fetch("https://api.shvx.in/api/v1/lead/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eGzFywzn9i0S",
        // Authorization: "Bearer n46aDZzLC4MR", //dummy for testing
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          console.log("OK");
          setFormData({
            name: "",
            project: "",
            contactNumber: "",
            email: "",
          });
          setErrors({});
          navigate("/thank-you?formType=callback");
        } else {
          console.log("FAILED");
        }
      })
      .catch(() => console.log("FAILED"));
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsZoomed(false);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setIsZoomed(false);
  };

  const handleImageZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const floorPlans = {
    "nakshatra-aazstha": [
      {
        src: Aazstha_FloorPlan_image1,
        alt: "Floor Plan 1A",
        text: "Floor Plan 1A",
      },
      {
        src: Aazstha_FloorPlan_image2,
        alt: "Floor Plan 1B",
        text: "Floor Plan 1B",
      },
    ],
    "nakshatra-gokul": [
      {
        src: Gokul_FloorPlan_image1,
        alt: "Floor Plan Gokul 1",
        text: "Floor Plan Gokul 1",
      },
      {
        src: Gokul_FloorPlan_image2,
        alt: "Floor Plan Gokul 2",
        text: "Floor Plan Gokul 2",
      },
    ],

    "nakshatra-prithvi": [
      {
        src: Prithvi_FloorPlan_image1,
        alt: "Floor Plan Prithvi 1",
        text: "Floor Plan Prithvi 1",
      },
      {
        src: Prithvi_FloorPlan_image2,
        alt: "Floor Plan Prithvi 2",
        text: "Floor Plan Prithvi 2",
      },
      {
        src: Prithvi_FloorPlan_image3,
        alt: "Floor Plan Prithvi 3",
        text: "Floor Plan Prithvi 3",
      },
      {
        src: Prithvi_FloorPlan_image4,
        alt: "Floor Plan Prithvi 4",
        text: "Floor Plan Prithvi 4",
      },
      {
        src: Prithvi_FloorPlan_image5,
        alt: "Floor Plan Prithvi 5",
        text: "Floor Plan Prithvi 5",
      },
    ],

    "nakshatra-aarambh": [
      {
        src: Aarambh_FloorPlan_image1,
        alt: "Floor Plan Aarambh 1",
        text: "Floor Plan Aarambh 1",
      },
      {
        src: Aarambh_FloorPlan_image2,
        alt: "Floor Plan Aarambh 2",
        text: "Floor Plan Aarambh 2",
      },
    ],

    "shree-ram-nagar": [
      {
        src: ShreeRam_FloorPlan_image1,
        alt: "ShreeRam floorPlan 1",
        text: "Shree Ram FloorPlan 1",
      },
      {
        src: ShreeRam_FloorPlan_image2,
        alt: "ShreeRam floorPlan 2",
        text: "Shree Ram FloorPlan 2",
      },
      {
        src: ShreeRam_FloorPlan_image3,
        alt: "ShreeRam floorPlan 3",
        text: "Shree Ram FloorPlan 3",
      },
      {
        src: ShreeRam_FloorPlan_image4,
        alt: "ShreeRam floorPlan 4",
        text: "Shree Ram FloorPlan 4",
      },
      {
        src: ShreeRam_FloorPlan_image5,
        alt: "ShreeRam floorPlan 5",
        text: "Shree Ram FloorPlan 5",
      },
      {
        src: ShreeRam_FloorPlan_image6,
        alt: "ShreeRam floorPlan 6",
        text: "Shree Ram FloorPlan 6",
      },
    ],
    "nakshatra-auris": [
      {
        src: Auris_FloorPlan_image1,
        alt: "Floor Plan Auris 1",
        text: "Flor Plan For Nakshatra Auris 1",
      },
      {
        src: Auris_FloorPlan_image2,
        alt: "Floor Plan Auris 2",
        text: "Flor Plan For Nakshatra Auris 2",
      },
      {
        src: Auris_FloorPlan_image3,
        alt: "Floor Plan Auris 3",
        text: "Flor Plan For Nakshatra Auris 3",
      },
      {
        src: Auris_FloorPlan_image4,
        alt: "Floor Plan Auris 4",
        text: "Flor Plan For Nakshatra Auris 4",
      },
    ],
  };

  const mapUrls = {
    "nakshatra-aazstha":
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3763.1835865188814!2d72.8259167!3d19.4044722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDI0JzE2LjEiTiA3MsKwNDknMzMuMyJF!5e0!3m2!1sen!2sin!4v1723619959661!5m2!1sen!2sin",
    "nakshatra-gokul":
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.0799892038813!2d72.8076323!3d19.4521178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a9e117835c75%3A0xa50c8f7fb2fcbbfb!2sNakshatra%20Gokul!5e0!3m2!1sen!2sin!4v1723784058724!5m2!1sen!2sin",
    "nakshatra-prithvi":
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3763.1835865188814!2d72.8259167!3d19.4044722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDI0JzE2LjEiTiA3MsKwNDknMzMuMyJF!5e0!3m2!1sen!2sin!4v1723791203599!5m2!1sen!2sin",
    "nakshatra-aarambh":
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3764.102074758767!2d72.8601625752126!3d19.36473238190025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDIxJzUzLjAiTiA3MsKwNTEnNDUuOSJF!5e0!3m2!1sen!2sin!4v1723802391029!5m2!1sen!2sin",
    "shree-ram-nagar":
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15048.22898514741!2d72.798578!3d19.4530985!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7abba83d05655%3A0xaadcebe88e8bd44d!2sShreeRam%20Complex!5e0!3m2!1sen!2sin!4v1724930039375!5m2!1sen!2sin",
    "nakshatra-auris":
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15050.997714764659!2d72.8049024!3d19.4232295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a95b21e3e697%3A0x4a90644a230b294b!2sNakshatra%20Auris!5e0!3m2!1sen!2sin!4v1724931972656!5m2!1sen!2sin",
  };

  const brochureUrls = {
    "nakshatra-aazstha":
      "https://drive.google.com/file/d/1DTuf6fhQDhviFkHR8H_O4P7JPqbCco4C/view?usp=sharing",
    "nakshatra-gokul":
      "https://drive.google.com/file/d/1cj2wMpgOr5YAw-hbpjSQ-L9uPNbsvaIa/view?usp=sharing",
    "nakshatra-prithvi":
      "https://drive.google.com/file/d/1Gyhotnekk9L80ZSyvgc1F1NbN1TeBZEF/view?usp=sharing",
    "nakshatra-aarambh":
      "https://drive.google.com/file/d/1rKi5iXb7l5T41cYq1ze-pq-Sx9GERtHz/view?usp=sharing",
    "shree-ram-nagar":
      "https://drive.google.com/file/d/1hQiSQ9UA5jjaSTYrIk7uJVsx-Iv9Re7o/view?usp=sharing",
    "nakshatra-auris":
      "https://drive.google.com/file/d/1ThFkE_mG0YZKr1y9JSVs8pYAWguU9RxQ/view?usp=sharing",
  };

  const qrCodeUrls = {
    "nakshatra-aazstha": Aazstha_QR,
    "nakshatra-gokul": Gokul_QR,
    "nakshatra-prithvi": Prithvi_QR,
    "shree-ram-nagar": ShreeRam_QR,
    // "nakshatra-auris": ,
  };
  const reraDetails = {
    "nakshatra-aazstha": {
      text: [
        "MahaRERA Reg No: Nakshatra Greens Phase 1 is registered with MahaRERA bearing RERA NO. P99000051584",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },

    "nakshatra-gokul": {
      text: [
        "MahaRERA Reg No: Nakshatra Greens Phase 1 is registered with MahaRERA bearing RERA NO. P99000056045",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "nakshatra-prithvi": {
      text: [
        "MahaRERA Reg No: Nakshatra Prithvi is registered with MahaRERA bearing RERA NO. P99000055701",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "nakshatra-aarambh": {
      text: [
        "MahaRERA Reg No: Nakshatra Greens Phase 1 is registered with MahaRERA bearing RERA NO. P99000027563",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "shree-ram-nagar": {
      text: [
        "MahaRERA Reg No: shree Ram Nagar Complex is registered with MahaRERA bearing RERA NO. P99000048744",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "nakshatra-auris": {
      text: [
        "MahaRERA Reg No: Nakshatra Auris is registered with MahaRERA bearing RERA NO. P99000053022",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
  };

  const currentFloorPlans = floorPlans[projectId] || [];
  const mapUrl = mapUrls[projectId] || "";
  const brochureUrl = brochureUrls[projectId] || "";
  const qrCodeUrl = qrCodeUrls[projectId] || "";

  return (
    <>
      <div className="floor-plans">
        <h2 className="resources-title">RESOURCES</h2>
        <p className="floor-plans-title">Floor Plans</p>
        <div className="azatha-image-container">
          {currentFloorPlans.map((plan, index) => (
            <div
              key={index}
              className="azatha-image-item"
              onClick={() => handleImageClick(plan.src)}
            >
              <img
                src={plan.src}
                alt={plan.alt}
                className="azatha-floor-plan-image"
              />
              <div className="azatha-image-title">{plan.text}</div>
            </div>
          ))}
        </div>

        {selectedImage && (
          <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="modal-close-btn" onClick={handleCloseModal}>
                &times;
              </span>
              <div className="modal-image-container">
                <img
                  src={selectedImage}
                  alt="Full Size"
                  className={`modal-image ${isZoomed ? "zoomed" : ""}`}
                  onClick={handleImageZoom}
                />
              </div>
            </div>
          </div>
        )}

        {brochureUrl && (
          <a
            className="download-brochure"
            target="_blank"
            href={brochureUrl}
            download
          >
            Click Here to download Brochure
          </a>
        )}

        <div className="Project-location-container">
          <h1 className="floor-plans-project-location-title">
            PROJECT LOCATION
          </h1>
          <div>
            {mapUrl && (
              <iframe
                src={mapUrl}
                style={{ border: "0", borderRadius: "0px 40px 0px 0px" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps Location"
              />
            )}
          </div>
        </div>
        <div className="rera-details-title-container">
          <h1 className="rera-details-title">RERA DETAILS</h1>
        </div>
        <div className="rera-details-wrapper">
          <div className="rera-details-container">
            {reraDetails[projectId] && (
              <>
                {reraDetails[projectId].text.map((line, index) => (
                  <p key={index}>
                    {index === 1 ? (
                      <>
                        {line.split("http://maharera.mahaonline.gov.in")[0]}
                        <a
                          href="http://maharera.mahaonline.gov.in"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="rera-link"
                        >
                          http://maharera.mahaonline.gov.in
                        </a>
                        {line.split("http://maharera.mahaonline.gov.in")[1]}
                      </>
                    ) : (
                      <span>{line}</span>
                    )}
                  </p>
                ))}
              </>
            )}
          </div>

          {qrCodeUrl && (
            <img src={qrCodeUrl} alt="QR Code" className="floorplan-aazastha-qr-code-image " />
          )}
        </div>
        {/* <h1 className="enquiry-form-title">ENQUIRY FORM</h1> */}
      </div>

      <div className="request-callback-form">
        <div>
          <h1>ENQUIRY FORM</h1>
        </div>
        <div className="callback-form-container">
          <div className="callback-logo">
            <img src={callImage} alt="Call Icon" />
          </div>
          <form className="callback-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name*"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <div className="error">{errors.name}</div>}
            <select
              name="project"
              value={formData.project}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Interested in which project
              </option>
              <option value="Nakshatra Veda Phase I">
                Nakshatra Veda Phase I
              </option>
              <option value="Nakshatra Veda Phase II">
                Nakshatra Veda Phase II
              </option>
              <option value="Nakshatra Aazstha">Nakshatra Aazstha</option>
              <option value="Nakshatra Gokul">Nakshatra Gokul</option>
              <option value="Nakshatra Prithvi">Nakshatra Prithvi</option>
              <option value="Nakshatra Aarambh">Nakshatra Aarambh</option>
              <option value="Shree Ram Nagar Complex">
                Shree Ram Nagar Complex
              </option>
              <option value="Nakshatra Auris">Nakshatra Auris</option>
            </select>
            {errors.project && <div className="error">{errors.project}</div>}
            <input
              type="text"
              placeholder="Contact Number*"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
            {errors.contactNumber && (
              <div className="error">{errors.contactNumber}</div>
            )}
            <input
              type="email"
              placeholder="Email ID*"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <div className="error">{errors.email}</div>}

            <button type="submit">REQUEST CALLBACK</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default FloorPlansAzastha;
