import React from 'react';
import AmenitiesSectionDesktop from './AmenitiesSectionDesktop';
import { useMediaQuery, useTheme } from "@mui/material";
import AmenitiesSectionMobile from './AmenitiesSectionMobile';

const AmenitiesSection = () => {
 
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile ? <AmenitiesSectionMobile /> : <AmenitiesSectionDesktop />;
};
  

export default AmenitiesSection;
