import React, { forwardRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import ProjectGalleryDesktop from './ProjectGalleryDesktop';
import ProjectGalleryMobile from './ProjectGalleryMobile';

const ProjectGallery = forwardRef((props, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <section ref={ref}>
      {isMobile ? <ProjectGalleryMobile /> : <ProjectGalleryDesktop />}
    </section>
  );
});

export default ProjectGallery;
