import React from "react";
import { Box, Grid } from "@mui/material";
import "../../styles/AmenitiesSection.css";

// Import icons
import vitrifiedTilesIcon from "../../images/vitrifiedTilesIcon.svg";
import graniteKitchenIcon from "../../images/graniteKitchenIcon.svg";
import earthquakeResistanceIcon from "../../images/earthquakeResistanceIcon.svg";
import kitchenPlatformIcon from "../../images/kitchenPlatformIcon.svg";
import ccSinkIcon from "../../images/ccSinkIcon.svg";
import clubHouseIcon from "../../images/clubHouseIcon.svg";
import swimmingPoolIcon from "../../images/swimmingPoolIcon.svg";
import partyLawnIcon from "../../images/partyLawnIcon.svg";
import kidsPlayAreaIcon from "../../images/kidsPlayAreaIcon.svg";
import amphiTheatreIcon from "../../images/amphiTheatreIcon.svg";
import groundParkingIcon from "../../images/groundParkingIcon.svg";

const AmenitiesSectionMobile = () => {
  return (
    <div className="amenities-section-mobile">
      <Box className="amenities-section-mobile-internal-amenities">
        <div className="amenities-section-mobile-section-title">
          INTERNAL <br /> AMENITIES
        </div>
        <Grid
          container
          spacing={2}
          className="amenities-section-mobile-amenities-internal-grid"
        >
          <Grid item xs={6}>
            <img
              src={vitrifiedTilesIcon}
              alt="Vitrified Tiles"
              className="amenities-section-mobile-amenity-internal-icon"
            />
            <div className="amenities-section-mobile-amenity-internal-text">
              VITRIFIED <br /> TILES
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={graniteKitchenIcon}
              alt="Granite Kitchen"
              className="amenities-section-mobile-amenity-internal-icon"
            />
            <div className="amenities-section-mobile-amenity-internal-text">
              GRANITE <br /> KITCHEN
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={earthquakeResistanceIcon}
              alt="Earthquake Resistance"
              className="amenities-section-mobile-amenity-internal-icon"
            />
            <div className="amenities-section-mobile-amenity-internal-text">
              EARTHQUAKE
              <br /> RESISTANCE
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={kitchenPlatformIcon}
              alt="Kitchen Platform"
              className="amenities-section-mobile-amenity-internal-icon"
            />
            <div className="amenities-section-mobile-amenity-internal-text">
              KITCHEN <br /> PLATFORM
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={ccSinkIcon}
              alt="CC Sink"
              className="amenities-section-mobile-amenity-internal-icon"
            />
            <div className="amenities-section-mobile-amenity-internal-text">SS SINK</div>
          </Grid>
          <Grid item xs={6}>
            <div className="amenities-section-mobile-and-more">and more</div>
          </Grid>
        </Grid>
      </Box>

      <Box className="amenities-section-mobile-external-amenities">
        <div className="amenities-section-mobile-section-external-title">
          EXTERNAL <br />
          AMENITIES
        </div>
        <div className="amenities-section-mobile-sub-title">
          25+ Luxurious Amenities <br /> including
        </div>
        <Grid
          container
          spacing={2}
          className="amenities-section-mobile-amenities-external-grid"
        >
          <Grid item xs={6}>
            <img
              src={clubHouseIcon}
              alt="Club House"
              className="amenities-section-mobile-amenity-external-icon"
            />
            <div className="amenities-section-mobile-amenity-external-text">
              CLUB <br />
              HOUSE
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={swimmingPoolIcon}
              alt="Swimming Pool"
              className="amenities-section-mobile-amenity-external-icon"
            />
            <div className="amenities-section-mobile-amenity-external-text">
              SWIMMING <br /> POOL
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={partyLawnIcon}
              alt="Party Lawn"
              className="amenities-section-mobile-amenity-external-icon"
            />
            <div className="amenities-section-mobile-amenity-external-text">
              PARTY <br />
              LAWN
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={kidsPlayAreaIcon}
              alt="Kids Play Area"
              className="amenities-section-mobile-amenity-external-icon"
            />
            <div className="amenities-section-mobile-amenity-external-text">
              KIDS PLAY <br /> AREA
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={amphiTheatreIcon}
              alt="Amphi Theatre"
              className="amenities-section-mobile-amenity-icon"
            />
            <div className="amenities-section-mobile-amenity-external-text">
              AMPHI <br /> THEATRE
            </div>
          </Grid>
          <Grid item xs={6}>
            <img
              src={groundParkingIcon}
              alt="Ground Parking"
              className="amenities-section-mobile-amenity-icon"
            />
            <div className="amenities-section-mobile-amenity-external-text">
              GROUND <br /> PARKING
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AmenitiesSectionMobile;
