import React, { useState } from "react";
import { Container, Grid, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import "../../styles/OtherProjects.css";

import Nakshatra_Aarambh from "../../images/Nakshatra_Aarambh.webp";
import Nakshatra_Gokul from "../../images/Nakshatra_Gokul.webp";
import Nakshatra_Aazstha from "../../images/Nakshatra_Aazstha.webp";
import Nakshatra_Prithvi from "../../images/Nakshatra_Prithvi.webp";
import { useNavigate } from "react-router-dom";

import readMoreIcon from "../../images/bottom_arrow.svg";
import OtherProject_ShreeRamNagar from "../../images/OtherProject_ShreeRamNagar.png";
import OtherProject_Nakshatra_Auris from "../../images/OtherProject_Nakshatra_Auris.png";

const OtherProjectsMobile = () => {
  const { projectId } = useParams();
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  // Define project images and their IDs
  const projects = [
    {
      id: "nakshatra-aazstha",
      image: Nakshatra_Aazstha,
      name: "NAKSHATRA<br>AAZSTHA",
      location: "Vasai",
    },
    {
      id: "nakshatra-prithvi",
      image: Nakshatra_Prithvi,
      name: "NAKSHATRA<br>PRITHVI",
      location: "Vasai",
    },
    {
      id: "nakshatra-gokul",
      image: Nakshatra_Gokul,
      name: "NAKSHATRA<br>GOKUL",
      location: "Virar",
    },
    {
      id: "shree-ram-nagar",
      image: OtherProject_ShreeRamNagar,
      name: "SHREE RAM <br/> NAGAR <br/> COMPLEX",
      location: "Virar",
    },
    {
      id: "nakshatra-auris",
      image: OtherProject_Nakshatra_Auris,
      name: "NAKSHATRA<br>AURIS",
      location: "Nala Sopara",
    },
    // {
    //   id: "nakshatra-aarambh",
    //   image: Nakshatra_Aarambh,
    //   name: "NAKSHATRA<br>AARAMBH",
    // },
  ];

  // Filter out the project that matches the projectId
  const filteredProjects = projects.filter(
    (project) => project.id !== projectId
  );

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  const handleProjectClick = (id) => {
    navigate(`/projects/${id}`);
  };

  return (
    <Container className="OtherProjectsMobile-section">
      <div className="OtherProjectsMobile-section-title">OTHER PROJECTS</div>
      <Grid container spacing={3} className="OtherProjectsMobile-image-grid">
        {filteredProjects.slice(0, 3).map((project) => (
          <Grid
            item
            xs={12}
            key={project.id}
            className="OtherProjectsMobile-image-container"
          >
            <div
              className="OtherProjectsMobile-image-wrapper"
              onClick={() => handleProjectClick(project.id)}
            >
              <img
                src={project.image}
                alt={project.name}
                className="OtherProjectsMobile-project-image"
              />
              <div className="OtherProjectsMobile-image-overlay">
                <div
                  className="OtherProjectsMobile-image-title"
                  dangerouslySetInnerHTML={{ __html: project.name }}
                />
                <p className="OtherProjectsMobile-location">
                  {project.location}
                </p>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      {showMore && (
        <Grid container spacing={3} className="OtherProjectsMobile-image-grid">
          {filteredProjects.slice(3).map((project) => (
            <Grid
              item
              xs={12}
              key={project.id}
              className="OtherProjectsMobile-image-container"
            >
              <div
                className="OtherProjectsMobile-image-wrapper"
                onClick={() => handleProjectClick(project.id)}
              >
                <img
                  src={project.image}
                  alt={project.name}
                  className="OtherProjectsMobile-project-image"
                />
                <div className="OtherProjectsMobile-image-overlay">
                  <div
                    className="OtherProjectsMobile-image-title"
                    dangerouslySetInnerHTML={{ __html: project.name }}
                  />
                  <p className="OtherProjectsMobile-location">
                    {project.location}
                  </p>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
      <Button
        onClick={handleReadMore}
        className="OtherProjectsMobile-read-more-button"
      >
        <img
          src={readMoreIcon}
          alt="Read More"
          className={`OtherProjectsMobile-read-more-icon ${
            showMore ? "rotated" : ""
          }`}
        />
      </Button>
    </Container>
  );
};

export default OtherProjectsMobile;
