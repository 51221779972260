import React from "react";
import { useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

// import Nakshatra_veda1_image1 from "../../images/Carousel_nakshatra-veda-i_image1.png";
// import Nakshatra_veda1_image2 from "../../images/Carousel_nakshatra-veda-i_image2.jpg";
// import Nakshatra_veda1_image3 from "../../images/Carousel_nakshatra-veda-i_image3.jpg";

import Nakshatra_veda1_image1 from "../../images/Carousel_nakshatra-veda-i_elevation_image1.jpg";
import Nakshatra_veda1_image2 from "../../images/Carousel_nakshatra-veda-i_elevation_image2.jpg";
import Nakshatra_veda1_image3 from "../../images/Carousel_nakshatra-veda-i_elevation_image3.jpg";
import Nakshatra_veda1_image4 from "../../images/Carousel_nakshatra-veda-i_amenities_image4.jpg";
import Nakshatra_veda1_image5 from "../../images/Carousel_nakshatra-veda-i_amenities_image5.jpg";
import Nakshatra_veda1_image6 from "../../images/Carousel_nakshatra-veda-i_amenities_image6.jpg";
// import Nakshatra_veda1_image7 from "../../images/Carousel_nakshatra-veda-i_interiors_image7.jpg";
// import Nakshatra_veda1_image8 from "../../images/Carousel_nakshatra-veda-i_interiors_image8.jpg";
// import Nakshatra_veda1_image9 from "../../images/Carousel_nakshatra-veda-i_interiors_image9.jpg";

import Nakshatra_Aazstha_image1 from "../../images/Carousel_Nakshatra Aazstha_image1.png";
// import Nakshatra_Aazstha_image2 from "../../images/Carousel_Nakshatra Aazstha_image2.png";
// import Nakshatra_Aazstha_image3 from "../../images/Carousel_Nakshatra Aazstha_image3.png";
import Nakshatra_Aazstha_image4 from "../../images/Carousel_Nakshatra Aazstha_image4.png";
import Nakshatra_Aazstha_image5 from "../../images/Carousel_Nakshatra Aazstha_image5.png";
import Nakshatra_Aazstha_image6 from "../../images/Carousel_Nakshatra Aazstha_image6.png";
import Nakshatra_Aazstha_image7 from "../../images/Carousel_Nakshatra Aazstha_image7.png";
import Nakshatra_Aazstha_image8 from "../../images/Carousel_Nakshatra Aazstha_image8.png";


// import Nakshatra_Gokul_image1 from "../../images/Nakshatra_Gokul_image1.png";
import Nakshatra_Gokul_image1 from "../../images/Nakshatra_Gokul_new_image1.png";
import Nakshatra_Gokul_image2 from "../../images/Nakshatra_Gokul_image2.png";
import Nakshatra_Gokul_image3 from "../../images/Nakshatra_Gokul_image3.png";
import Nakshatra_Gokul_image4 from "../../images/Nakshatra_Gokul_image4.png";
import Nakshatra_Gokul_image5 from "../../images/Nakshatra_Gokul_image5.png";

// import Nakshatra_Prithvi_image1 from "../../images/Nakshatra_Prithvi_image1.png";
// import Nakshatra_Prithvi_image2 from "../../images/Nakshatra_Prithvi_image2.png";
// import Nakshatra_Prithvi_image3 from "../../images/Nakshatra_Prithvi_image3.png";

import Nakshatra_Prithvi_image1 from "../../images/Nakshatra_Prithvi_new_image1.png";
import Nakshatra_Prithvi_image2 from "../../images/Nakshatra_Prithvi_new_image2.png";
import Nakshatra_Prithvi_image3 from "../../images/Nakshatra_Prithvi_new_image3.png";
import Nakshatra_Prithvi_image4 from "../../images/Nakshatra_Prithvi_new_image4.png";
import Nakshatra_Prithvi_image5 from "../../images/Nakshatra_Prithvi_new_image5.png";
import Nakshatra_Prithvi_image6 from "../../images/Nakshatra_Prithvi_new_image6.png";

import Nakshatra_Aarambh_image1 from "../../images/Nakshatra_Aarambh_image1.png";

import Carousel_ShreeRamNagar_image1 from '../../images/Carousel_ShreeRamNagar_image1.png'

import Carousel_Nakshatra_Auris_image1 from '../../images/Carousel_Nakshatra_Auris_image1.png'
import Carousel_Nakshatra_Auris_image2 from '../../images/Carousel_Nakshatra_Auris_image2.png'
import "../../styles/ProjectPageCarousel.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProjectPageCarouselDesktop = () => {
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { projectId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);

  // Define images and details based on projectId
  const projectData = {
    "nakshatra-veda-i": {
      images: [
        Nakshatra_veda1_image2,
        Nakshatra_veda1_image1,
        Nakshatra_veda1_image3,
        Nakshatra_veda1_image4,
        Nakshatra_veda1_image5,
        Nakshatra_veda1_image6,
      //   Nakshatra_veda1_image7,
      //   Nakshatra_veda1_image8,
      //   Nakshatra_veda1_image9
      ],
      details: [
        { label: "Project Area", value: "6 Acres" },
        { label: "Configuration", value: "1BHK , 2BHK , <br/>3BHK" },
        { label: "Sizes", value: "430 sq.ft.<br/>795 sq.ft." },
        { label: "Project Size", value: "6 Buildings" },
        { label: "Launch Date", value: "Dec 2026" },
        {
          label: "Possession Status",
          value:
            "Rera Possession - December 2025<br/>Target Possession - December 2023",
        },
        { label: "Avg. Price", value: "42L onwards" },
      ],
    },
    "nakshatra-aazstha": {
      images: [
        Nakshatra_Aazstha_image1,
        // Nakshatra_Aazstha_image2,
        // Nakshatra_Aazstha_image3,
        Nakshatra_Aazstha_image4,
        Nakshatra_Aazstha_image5,
        Nakshatra_Aazstha_image6,
        Nakshatra_Aazstha_image7,
        Nakshatra_Aazstha_image8
      ],
      details: [
        { label: "Project Area", value: "3 Acres" },
        { label: "Sizes", value: "390 sq.ft. - 502 sq.ft." },
        { label: "Project Size", value: "6 Towers" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Avg. Price", value: "₹9.05 K/sq.ft" },
        { label: "Possession Starts", value: "December 2023" },
        { label: "Configurations", value: "1 BHK, 1.5 BHK, 2 BHK" },
      ],
    },
    "nakshatra-gokul": {
      images: [
        Nakshatra_Gokul_image1,
        Nakshatra_Gokul_image2,
        Nakshatra_Gokul_image3,
        Nakshatra_Gokul_image4,
        Nakshatra_Gokul_image5,
      ],
      details: [
        { label: "Project Area", value: "0.75 Acres" },
        { label: "Sizes", value: "489 sq.ft - 518 sq.ft" },
        { label: "Project Size", value: "3 Buildings - 359 units" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Avg. Price", value: "46.82 L onwards" },
        { label: "Possession Starts", value: "December 2027" },
        { label: "Configurations", value: "1 BHK, 2 BHK, 3 BHK" },
      ],
    },
    "nakshatra-prithvi": {
      images: [
        Nakshatra_Prithvi_image1,
        Nakshatra_Prithvi_image2,
        Nakshatra_Prithvi_image3,
        Nakshatra_Prithvi_image4,
        Nakshatra_Prithvi_image5,
        Nakshatra_Prithvi_image6,
      ],
      details: [
        { label: "Project Area", value: "3 Acres" },
        { label: "Sizes", value: "390 sq.ft. - 502 sq.ft." },
        { label: "Project Size", value: "6 Towers" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Avg. Price", value: "₹9.05 K/sq.ft" },
        { label: "Possession Starts", value: "December 2023" },
        { label: "Configurations", value: "1 BHK, 1.5 BHK, 2 BHK" },
      ],
    },
    "nakshatra-aarambh": {
      images: [Nakshatra_Aarambh_image1],
      details: [
        { label: "Project Area", value: "0.75 Acres" },
        { label: "Sizes", value: "489 sq.ft. - 932 sq.ft." },
        { label: "Project Size", value: "3 Buildings - 359 units" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Avg. Price", value: "₹9.57 K/sq.ft - 10.07K / sq.ft." },
        { label: "Possession Starts", value: "December 2027" },
        { label: "Configurations", value: "1 BHK, 2 BHK, 3 BHK" },
      ],
    },
    "shree-ram-nagar":{
      images:[Carousel_ShreeRamNagar_image1],
      details: [
        { label: "Project Area", value: "0.99 acres" },
        { label: "Sizes", value: "292-690 sq.ft" },
        { label: "Project Size", value: "1 building: 65 units" },
        { label: "Avg. Price", value: "Price on request" },
        { label: "Possession Starts", value: "December 2026" },
        { label: "Configurations", value: "1, 2, 3 BHKs, Villas, Commercial Spaces" },
      ],
    },
    "nakshatra-auris":{
      images:[
        Carousel_Nakshatra_Auris_image1,
        Carousel_Nakshatra_Auris_image2
      ],
      details: [
        { label: "Project Area", value: "0.99 acres" },
        { label: "Sizes", value: "292-690 sq.ft" },
        { label: "Project Size", value: "1 building: 65 units" },
        { label: "Avg. Price", value: "Price on request" },
        { label: "Possession Starts", value: "December 2026" },
        { label: "Configurations", value: "1, 2, 3 BHKs, Villas, Commercial Spaces" },
      ],
    }
  };

  const data = projectData[projectId] || { images: [], details: [] };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className="desktop-ProjectPageCarousel-container">
      <AutoPlaySwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
        className="desktop-ProjectPageCarousel-carousel"
      >
        {data.images.map((image, index) => (
          <div
            key={index}
            className="desktop-ProjectPageCarousel-carousel-item"
          >
            <img
              src={image}
              alt={`Slide ${index}`}
              className="desktop-ProjectPageCarousel-carousel-image"
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <div className="desktop-ProjectPageCarousel-details-section">
        {data.details.map((detail, index) => (
          <div key={index} className="desktop-ProjectPageCarousel-details-item">
            <p className="desktop-ProjectPageCarousel-details-item-title">
              <strong>{detail.label}</strong>
            </p>
            <div
              className="desktop-ProjectPageCarousel-details-item-description"
              dangerouslySetInnerHTML={{ __html: detail.value }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectPageCarouselDesktop;
