import React, { forwardRef } from "react";
import { useMediaQuery } from "@mui/material";
import MeetJSBExpertsMobile from "./MeetJSBExpertsMobile";
import MeetJSBExpertsDesktop from "./MeetJSBExpertsDesktop";

const MeetJSBExperts = forwardRef((props, ref) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <section ref={ref}>
      {isMobile ? <MeetJSBExpertsMobile /> : <MeetJSBExpertsDesktop />}
    </section>
  );
});



export default MeetJSBExperts;
