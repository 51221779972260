import React, { forwardRef } from "react";
import CompletedProjectsDesktop from "./CompletedProjectsDesktop";
import CompletedProjectsMobile from "./CompletedProjectsMobile";
import { useMediaQuery, useTheme } from "@mui/material";

const CompletedProjects = forwardRef((props, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div ref={ref}>
      {isMobile ? <CompletedProjectsMobile /> : <CompletedProjectsDesktop />}
    </div>
  );
});

export default CompletedProjects;
