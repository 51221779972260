import React, { forwardRef } from "react";
import { Container } from "@mui/material";
import "../styles/IntroductionSection.css";

const IntroductionSection = forwardRef((props, ref) => {
  return (
    <Container className="introduction-section" ref={ref}>
      <h3 className="section-title">ABOUT US</h3>
      <div className="text-content-wrapper">
        <p className="section-text">
          At JSB Group, we believe your home is more than just property; it’s a
          personal sanctuary that reflects your aspirations. We’re passionate
          about turning your dreams into reality.
        </p>

        <p className="section-text">
          Buying a home is a significant milestone, and we're here to make the
          journey as smooth and enjoyable as possible. Our promise to you is
          simple: on time delivery and exceptional quality. Your trust in us is
          the cornerstone of our business.
        </p>
        <p className="section-text">
          Beyond building houses, we're building lasting relationships. Our
          focus on sustainability, combined with our drive for innovation,
          ensures we create not just homes, but thriving communities. We’re
          committed to empowering our team and raising industry standards.
        </p>
      </div>

      <div className="highlight-section">
        <p className="section-highlight">
          For us, building a home is more than just bricks and mortar. It’s
          about making your dream a reality and being with you every step of the
          way to create a place you can truly call your own.
        </p>
      </div>
    </Container>
  );
});

export default IntroductionSection;
