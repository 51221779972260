import React from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/OtherProjects.css";
import { useNavigate } from "react-router-dom";

import Nakshatra_Aarambh from "../../images/Nakshatra_Aarambh.webp";
import Nakshatra_Gokul from "../../images/Nakshatra_Gokul.webp";
import Nakshatra_Aazstha from "../../images/Nakshatra_Aazstha.webp";
import Nakshatra_Prithvi from "../../images/Nakshatra_Prithvi.webp";

import OtherProject_ShreeRamNagar from '../../images/OtherProject_ShreeRamNagar.png'
import OtherProject_Nakshatra_Auris from '../../images/OtherProject_Nakshatra_Auris.png'

import leftArrow from "../../images/left-arrow.png";
import rightArrow from "../../images/right-arrow.png";

const OtherProjectsDesktop = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  // Define project images and their IDs
  const projects = [
    {
      id: "nakshatra-aazstha",
      image: Nakshatra_Aazstha,
      name: "NAKSHATRA<br>AAZSTHA",
      location:"Vasai"
    },
    {
      id: "nakshatra-prithvi",
      image: Nakshatra_Prithvi,
      name: "NAKSHATRA<br>PRITHVI",
      location:"Vasai"

    },
    {
      id: "nakshatra-gokul",
      image: Nakshatra_Gokul,
      name: "NAKSHATRA<br>GOKUL",
      location:"Virar"
    },
    // {
    //   id: "nakshatra-aarambh",
    //   image: Nakshatra_Aarambh,
    //   name: "NAKSHATRA<br>AARAMBH",
    // },
    {
      id:"shree-ram-nagar",
      image:OtherProject_ShreeRamNagar,
      name:"SHREE RAM <br/> NAGAR <br/> COMPLEX",
      location:"Virar"
    },
    {
      id: "nakshatra-auris",
      image: OtherProject_Nakshatra_Auris,
      name: "NAKSHATRA<br>AURIS",
      location:"Nala Sopara"
    },
  ];

  // Filter out the project that matches the projectId
  const filteredProjects = projects.filter(
    (project) => project.id !== projectId
  );

  const handleProjectClick = (id) => {
    navigate(`/projects/${id}`);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="OtherProjects">
      <h2>OTHER PROJECTS</h2>
      <Slider {...settings}>
        {filteredProjects.map((project) => (
          <div
            className="OtherProjects-slide"
            key={project.id}
            onClick={() => handleProjectClick(project.id)}
          >
            <img
              src={project.image}
              alt={project.name}
              className="OtherProjects-image"
            />
            <div className="OtherProjects-overlay">
              <div className="OtherProjects-text">
                <h3 dangerouslySetInnerHTML={{ __html: project.name }} />
                {/* <p>Location</p> */}
                <p>{project.location}</p> 
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="OtherProjects-arrow OtherProjects-next-arrow"
      onClick={onClick}
    >
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="OtherProjects-arrow OtherProjects-prev-arrow"
      onClick={onClick}
    >
      <img src={leftArrow} alt="Previous" />
    </div>
  );
};

export default OtherProjectsDesktop;
