import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import DesktopHomePage from "./DesktopHomePage";
import MobileHomePage from "./MobileHomePage";

const HomePage = ({ scrollToSection }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile ? (
    <MobileHomePage scrollToSection={scrollToSection} />
  ) : (
    <DesktopHomePage scrollToSection={scrollToSection} />
  );
};

export default HomePage;
