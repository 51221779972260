import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/CompletedProjects.css";

import image1 from "../../images/nakshatra greens phase I.png";
import image2 from "../../images/Nakshatra greens phase ii.png";
import image3 from "../../images/nakshatra primus.png";
import image4 from "../../images/nakshatra pride I.png";
import image5 from "../../images/nakshatra pride II.webp";
import image6 from "../../images/nakshatra ozone.webp";

import image7 from "../../images/sai nakshatra trident.png";
import image8 from "../../images/Ritu paradise.webp";
import image9 from "../../images/Garden court.webp";
import image10 from "../../images/nakshatra tower.webp";
import image11 from "../../images/nakshatraAarambh_Mobile.png";

import nakshatra_residency from "../../images/nakshatra residency.png";
import leftArrow from "../../images/left-arrow.png";
import rightArrow from "../../images/right-arrow.png";

const CompletedProjectsDesktop = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="completed-projects">
      <h2>COMPLETED PROJECTS</h2>
      <Slider {...settings}>
        <div className="project-slide">
          <img src={image1} alt="Project 1" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                NAKSHATRA <br />
                GREEN <br />
                PHASE I
              </h3>

              <p>Naigaon</p>
            </div>
          </div>
        </div>
        <div className="project-slide">
          <img src={image2} alt="Project 2" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                NAKSHATRA <br />
                GREEN <br />
                PHASE II
              </h3>

              <p>Naigaon</p>
            </div>
          </div>
        </div>
        <div className="project-slide">
          <img src={image3} alt="Project 3" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                NAKSHATRA <br /> PRIMUS
              </h3>
              <p>Naigaon</p>
            </div>
          </div>
        </div>

        <div className="project-slide">
          <img src={image4} alt="Project 4" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                NAKSHATRA <br />
                PRIDE I & II
              </h3>
              <p>Naigaon</p>
            </div>
          </div>
        </div>

        <div className="project-slide">
          <img src={image6} alt="Project 4" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                NAKSHATRA <br /> OZONE
              </h3>
              <p>Naigaon</p>
            </div>
          </div>
        </div>
        <div className="project-slide">
          <img
            src={nakshatra_residency}
            alt="Project 4"
            className="project-image"
          />
          <div className="overlay">
            <div className="text">
              <h3>
                NAKSHATRA <br /> RESIDENCY
              </h3>
              <p>Makane Kapase</p>
            </div>
          </div>
        </div>

        <div className="project-slide">
          <img src={image8} alt="Project 4" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                RITU <br /> PARADISE
              </h3>
              <p> Mira-Bhayander</p>
            </div>
          </div>
        </div>

        <div className="project-slide">
          <img src={image9} alt="Project 4" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                GARDEN <br /> COURT
              </h3>
              <p> Bhayander West</p>
            </div>
          </div>
        </div>

        <div className="project-slide">
          <img src={image10} alt="Project 4" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                NAKSHATRA <br /> TOWER
              </h3>
              <p> Bhayander East</p>
            </div>
          </div>
        </div>

        <div className="project-slide">
          <img src={image11} alt="Project 4" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                NAKSHATRA <br /> AARAMBH
              </h3>
              <p> Naigaon</p>
            </div>
          </div>
        </div>

        <div className="project-slide">
          <img src={image7} alt="Project 4" className="project-image" />
          <div className="overlay">
            <div className="text">
              <h3>
                SAI <br /> NAKSHATRA <br /> TRIEDENT
              </h3>
              <p>Virar West</p>
            </div>
          </div>
        </div>
      </Slider>
      <button className="back-to-top" onClick={scrollToTop}>
        Back to Top
      </button>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrow next-arrow" onClick={onClick}>
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrow prev-arrow" onClick={onClick}>
      <img src={leftArrow} alt="Previous" />
    </div>
  );
};

export default CompletedProjectsDesktop;
