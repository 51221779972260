import React from "react";
import ProjectPageCarouselDesktop from "./ProjectPageCarouselDesktop";
import ProjectPageCarouselMobile from "./ProjectPageCarouselMobile";
import { useMediaQuery, useTheme } from "@mui/material";

const ProjectPageCarousel = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile ? <ProjectPageCarouselMobile /> : <ProjectPageCarouselDesktop />;
};
  

export default ProjectPageCarousel;
