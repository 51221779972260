import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import "../styles/DesktopMasterpiecesSection.css";

import nakshatraVeda1_Image from "../images/nakshatraVeda1_Mobile.png";
import nakshatraAaztha_Image from "../images/nakshatraAaztha_Mobile.webp";
import nakshatraPrithvi_Image from "../images/nakshatraPrithvi_Mobile.webp";
import nakshatraGokul_Image from "../images/nakshatraGokul_Mobile.webp";
import nakshatraShree_Ram_Nagar_Image from "../images/nakshatraShree_Ram_Nagar_Image.png";
import nakshatraAuris_Image from "../images/nakshatra_Auris_image.png";

// import nakshatraAarambh_Image from "../images/nakshatraAarambh_Mobile.webp";

import { useNavigate } from "react-router-dom";

const DesktopMasterpiecesSection = () => {
  const navigate = useNavigate();

  const handleNavigateToProjects = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  return (
    <div className="desktop-masterpiece-section">
      <div className="desktop-masterpiece-section-title">
        {/* MASTERPIECES IN MOTION */}
        {/* Ongoing Projects */}
        ONGOING PROJECTS
      </div>
      {/* <div className="desktop-masterpiece-section-subtitle"> */}
        {/* (Ongoing Projects) */}
        {/* ( MASTERPIECES IN MOTION) */}
      {/* </div> */}
      <Grid container spacing={3} className="desktop-masterpiece-image-grid">
        <Grid item xs={4} className="desktop-masterpiece-image-container">
          <div
            className="desktop-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-veda-i")}
          >
            <img
              src={nakshatraVeda1_Image}
              alt="nakshatra-veda-i"
              className="desktop-masterpiece-project-image"
            />
            <div className="desktop-masterpiece-image-overlay">
              <div className="desktop-masterpiece-image-title">
                Nakshatra <br /> VEDA I & II
              </div>
              <div className="desktop-masterpiece-image-subtitle">Vasai</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={4} className="desktop-masterpiece-image-container">
          <div
            className="desktop-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-aazstha")}
          >
            <img
              src={nakshatraAaztha_Image}
              alt="nakshatra-aazstha"
              className="desktop-masterpiece-project-image"
            />
            <div className="desktop-masterpiece-image-overlay">
              <div className="desktop-masterpiece-image-title">
                NAKSHATRA AAZSTHA
              </div>
              <div className="desktop-masterpiece-image-subtitle">Vasai</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={4} className="desktop-masterpiece-image-container">
          <div
            className="desktop-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-prithvi")}
          >
            <img
              src={nakshatraPrithvi_Image}
              alt="nakshatra-prithvi"
              className="desktop-masterpiece-project-image"
            />
            <div className="desktop-masterpiece-image-overlay">
              <div className="desktop-masterpiece-image-title">
                NAKSHATRA <br /> PRITHVI
              </div>
              <div className="desktop-masterpiece-image-subtitle">Vasai</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={4} className="desktop-masterpiece-image-container">
          <div
            className="desktop-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-gokul")}
          >
            <img
              src={nakshatraGokul_Image}
              alt="nakshatra-gokul"
              className="desktop-masterpiece-project-image"
            />
            <div className="desktop-masterpiece-image-overlay">
              <div className="desktop-masterpiece-image-title">
                NAKSHATRA <br /> GOKUL
              </div>
              <div className="desktop-masterpiece-image-subtitle">Virar </div>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          className="desktop-masterpiece-image-container"
        >
          <div
            className="desktop-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("shree-ram-nagar")}
          >
            <img
              src={nakshatraShree_Ram_Nagar_Image}
              alt="shree-ram-nagar"
              loading="lazy"
              className="desktop-masterpiece-project-image"
            />
            <div className="desktop-masterpiece-image-overlay">
              <div className="desktop-masterpiece-image-title">
                SHREE RAM <br /> NAGAR COMPLEX
              </div>
              <div className="desktop-masterpiece-image-subtitle">Virar</div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className="desktop-masterpiece-image-container"
        >
          <div
            className="desktop-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-auris")}
          >
            <img
              src={nakshatraAuris_Image}
              alt="nakshatra-auris"
              loading="lazy"
              className="desktop-masterpiece-project-image"
            />
            <div className="desktop-masterpiece-image-overlay">
              <div className="desktop-masterpiece-image-title">
                NAKSHATRA <br /> AURIS
              </div>
              <div className="desktop-masterpiece-image-subtitle">
                Nala Sopara
              </div>
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={4} className="desktop-masterpiece-image-container">
          <div
            className="desktop-masterpiece-image-wrapper"
            onClick={() => handleNavigateToProjects("nakshatra-aarambh")}
          >
            <img
              src={nakshatraAarambh_Image}
              alt="AARAMBH"
              className="desktop-masterpiece-project-image"
            />
            <div className="desktop-masterpiece-image-overlay">
              <div className="desktop-masterpiece-image-title">
                NAKSHATRA <br /> AARAMBH
              </div>
              <div className="desktop-masterpiece-image-subtitle">Naigaon</div>
            </div>
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default DesktopMasterpiecesSection;
