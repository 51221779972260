import React from "react";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Container } from "@mui/material";
import logo from "../../images/JSB_Logo_latest.png";

// import initialBackground from "../../images/Nakshatra_Aarambh_Mobile_Home.webp";
// import secondBackground from "../../images/Nakshatra_Auris_Mobile_Home.webp";
// import thirdBackground from "../../images/Nakshatra_Pride_Mobile_Home.webp";
// import fourthBackground from "../../images/Nakshatra_Primus_Ozone_Mobile_Home.webp";

import initialBackground from "../../images/Homepage images - mobile-01-Veda.webp";
import secondBackground from "../../images/Homepage images - mobile-02-Aaztha.webp";
import thirdBackground from "../../images/Homepage images - mobile-03-Prithvi.webp";
import fourthBackground from "../../images/Homepage images - mobile-04-Gokul.webp";
import FifthBackground from "../../images/Homepage images - mobile-05-Auris.webp";

import "../../styles/MobileHomePage.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  { url: initialBackground, text: "Nakshatra Veda I & II, Vasai" },
  { url: secondBackground, text: "Aazstha, Vasai" },
  { url: thirdBackground, text: "Nakshatra Prithvi, Vasai" },
  { url: fourthBackground, text: "Nakshatra Gokul, Virar" },
  { url: FifthBackground, text: "Nakshatra Auris, Nalasopara" },
  { url: initialBackground, text: "Nakshatra Veda I & II, Vasai" },
  { url: secondBackground, text: "Aazstha, Vasai" },
  { url: thirdBackground, text: "Nakshatra Prithvi, Vasai" },
  { url: fourthBackground, text: "Nakshatra Gokul, Virar" },
  { url: FifthBackground, text: "Nakshatra Auris, Nalasopara" },
];

const MobileHomePage = ({ scrollToSection }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className="mobile-homepage">
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={3000}
        className="mobile-carousel"
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="mobile-carousel-item"
            style={{
              backgroundImage: `url(${image.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100vh",
              backgroundImage: `url(${image})`,
            }}
          >
            <img
              src={image.url}
              alt={`Slide ${index}`}
              loading="lazy"
              className="mobile-carousel-image"
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <header className="mobile-header">
        <img src={logo} alt="Logo" className="mobile-logo" loading="lazy" />
      </header>

      <Container className="mobile-content">
        <p className="mobile-headline">
          WHERE <br />
          <span className="mobile-highlight">
            QUALITY <br />
          </span>
          MEETS YOUR <br />
          <span className="mobile-highlight">DREAMS </span>
        </p>

        <br />
        <div className="mobile-text-above-button">
          <p>{images[activeStep].text}</p>
        </div>
        <button
          className="mobile-enquire-button"
          onClick={() => scrollToSection()}
        >
          ENQUIRE NOW
        </button>
      </Container>

      {/* <div className="mobile-footer">
        <nav className="mobile-nav-links">
          <a href="#about">ABOUT US</a>
          <a href="#projects">PROJECTS</a>
          <a href="#testimonials">TESTIMONIALS</a>
          <a href="#contact">CONTACT</a>
        </nav>
      </div> */}
    </div>
  );
};

export default MobileHomePage;
