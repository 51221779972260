import React, { useEffect, useRef } from "react";
import { Box, Card, CardContent } from "@mui/material";
import "../styles/InfoCards.css";

const InfoCards = ({ scrollToSection, sectionRefs }) => {
  const cardsData = [
    {
      title: "20<br />YEARS",
      subtitle: "of industry excellence",
      highlight: false,
      sectionRef: null,
    },
    {
      title: "1.6 MILLION<br />SQ. FT.",
      subtitle: "successfully delivered",
      highlight: false,
      sectionRef: sectionRefs.projectsRef,
    },
    {
      title: "3.3 MILLION<br />SQ. FT.",
      subtitle: "ongoing projects",
      highlight: true,
      sectionRef: sectionRefs.masterpiecesRef,
    },
    {
      title: "More than  <br/>2600+",
      subtitle: "satisfied customers",
      highlight: true,
      sectionRef: null,
    },
  ];

  const cardRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing after animation
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observe only valid elements
    cardRefs.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      // Unobserve only valid elements
      cardRefs.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <Box className="container">
      {cardsData.map((card, index) => (
        <Card
          key={index}
          className={`card ${card.highlight ? "highlight" : ""} ${
            !card.sectionRef ? "no-pointer" : ""
          }`}
          onClick={() => card.sectionRef && scrollToSection(card.sectionRef)}
          ref={(el) => (cardRefs.current[index] = el)}
        >
          <CardContent>
            <p
              className="title"
              dangerouslySetInnerHTML={{ __html: card.title }}
            />
            <p className="subtitle">{card.subtitle}</p>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default InfoCards;
