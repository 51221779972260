import React from 'react';
import { useMediaQuery } from '@mui/material';
import DesktopMasterpiecesSection from './DesktopMasterpiecesSection';
import MobileMasterpiecesSection from './MobileMasterpiecesSection';

const MasterpiecesSection = React.forwardRef((props, ref) => {
  const isDesktop = useMediaQuery('(min-width:769px)');
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <div ref={ref}>
      {isDesktop && <DesktopMasterpiecesSection />}
      {isMobile && <MobileMasterpiecesSection />}
    </div>
  );
});

export default MasterpiecesSection;
