import React from 'react';
import { Grid, Box } from '@mui/material';
import '../../styles/AmenitiesSection.css';

// Import icons
import vitrifiedTilesIcon from '../../images/vitrifiedTilesIcon.png';
import graniteKitchenIcon from '../../images/graniteKitchenIcon.png';
import earthquakeResistanceIcon from '../../images/earthquakeResistanceIcon.png';
import kitchenPlatformIcon from '../../images/kitchenPlatformIcon.png';
import ccSinkIcon from '../../images/ccSinkIcon.png';
import clubHouseIcon from '../../images/clubHouseIcon.png';
import swimmingPoolIcon from '../../images/swimmingPoolIcon.png';
import partyLawnIcon from '../../images/partyLawnIcon.png';
import kidsPlayAreaIcon from '../../images/kidsPlayAreaIcon.png';
import amphiTheatreIcon from '../../images/amphiTheatreIcon.png';
import groundParkingIcon from '../../images/groundParkingIcon.png';

const AmenitiesSectionDesktop = () => {
  return (
    <div className="amenities-section-desktop">
      <Box className="amenities-section-desktop-internal-amenities">
        <div className="amenities-section-desktop-section-title">
          INTERNAL AMENITIES
        </div>
        <Grid container className="amenities-section-desktop-amenities-grid">
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={vitrifiedTilesIcon} alt="Vitrified Tiles" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">VITRIFIED <br/> TILES</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={graniteKitchenIcon} alt="Granite Kitchen" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">GRANITE <br/> KITCHEN</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={earthquakeResistanceIcon} alt="Earthquake Resistance" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">EARTHQUAKE <br/> RESISTANCE</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={kitchenPlatformIcon} alt="Kitchen Platform" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">KITCHEN <br/> PLATFORM</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={ccSinkIcon} alt="CC Sink" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">CC  SINK</div>
            </div>
          </Grid>
        </Grid>
        <div className="amenities-section-desktop-and-more">and more</div>
      </Box>
      <Box className="amenities-section-desktop-external-amenities">
        <div className="amenities-section-desktop-section-title">
          EXTERNAL AMENITIES
        </div>
        <div className="amenities-section-desktop-sub-title">
          25+ Luxurious Amenities including
        </div>
        <Grid container className="amenities-section-desktop-amenities-grid">
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={clubHouseIcon} alt="Club House" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">CLUB <br/>HOUSE</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={swimmingPoolIcon} alt="Swimming Pool" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">SWIMMING <br/> POOL</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={partyLawnIcon} alt="Party Lawn" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">PARTY  <br/>LAWN</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={kidsPlayAreaIcon} alt="Kids Play Area" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">KIDS PLAY <br/> AREA</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={amphiTheatreIcon} alt="Amphi Theatre" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">AMPHI <br/> THEATRE</div>
            </div>
          </Grid>
          <Grid item xs={6} md={2} className="amenity-item">
            <img src={groundParkingIcon} alt="Ground Parking" className="amenities-section-desktop-amenity-icon" />
            <div className="text-container">
              <div className="amenities-section-desktop-amenity-text">GROUND <br/>PARKING</div>
            </div>
          </Grid>
        </Grid>
        <div className="amenities-section-desktop-and-more">and more</div>
      </Box>
    </div>
  );
};

export default AmenitiesSectionDesktop;
