import React, { forwardRef } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import OtherProjectsMobile from "./OtherProjectsMobile";
import OtherProjectsDesktop from "./OtherProjectsDesktop";

const OtherProjects = forwardRef((props, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile ? <OtherProjectsMobile ref={ref} /> : <OtherProjectsDesktop ref={ref} />;
});

export default OtherProjects;
