import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/FloorPlans.css";
import callImage from "../images/callImage.png";
import floorPlan1 from "../images/Veda1_Floor_Plan_image1.png";
import floorPlan2 from "../images/Veda1_Floor_Plan_image2.png";
import floorPlan3 from "../images/Veda1_Floor_Plan_image3.png";

// import floorPlan1 from "../images/1BHK-Floor-Plan.png";
// import floorPlan2 from "../images/2BHK-Floor-Plan.png";
// import floorPlan3 from "../images/3BHK-Floor-Plan.png";

import Veda_I from "../images/Veda_I.jpeg";
import Veda_II from "../images/Veda_II.jpeg";

import leftArrow from "../images/left-arrow.png";
import rightArrow from "../images/right-arrow.png";
import { useNavigate } from "react-router-dom";

const FloorPlans = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomed, setZoomed] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    project: "",
    contactNumber: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;

    if (name === "name") {
      newValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 100);
    }

    if (name === "contactNumber") {
      newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    }

    if (name === "email") {
      newValue = value.replace(/[^a-zA-Z0-9._%+-@]/g, "").slice(0, 100);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, project, contactNumber, email } = formData;

    if (!name) newErrors.name = "Name is required.";
    if (!project) newErrors.project = "Project selection is required.";
    if (!contactNumber) newErrors.contactNumber = "Contact number is required.";
    if (!email) newErrors.email = "Email is required.";

    // Validate name (max 100 characters, no special characters or emojis)
    if (name) {
      if (name.length > 100) {
        newErrors.name = "Name must be less than 100 characters.";
      } else if (!/^[a-zA-Z\s]*$/.test(name)) {
        newErrors.name = "Name cannot contain special characters or emojis.";
      }
    }

    // Validate email format
    if (
      email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
    ) {
      newErrors.email = "Email is invalid.";
    }

    // Validate contact number (must be exactly 10 digits)
    if (contactNumber && !/^\d{10}$/.test(contactNumber)) {
      newErrors.contactNumber = "Contact number must be exactly 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Log form data to check the values
    console.log(formData);

    fetch("https://api.shvx.in/api/v1/lead/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eGzFywzn9i0S",
        // Authorization: "Bearer n46aDZzLC4MR", //dummy for testing
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          console.log("OK");
          setFormData({
            name: "",
            project: "",
            contactNumber: "",
            email: "",
          });
          setErrors({});
          navigate("/thank-you?formType=callback");
        } else {
          console.log("FAILED");
        }
      })
      .catch(() => console.log("FAILED"));
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setZoomed(false); // Reset zoom state
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setZoomed(false); // Reset zoom state
  };

  const handleImageZoom = (e) => {
    e.stopPropagation();
    setZoomed((prevZoom) => !prevZoom);
  };
  return (
    <>
      <div className="floor-plans">
        <h2 className="resources-title">RESOURCES</h2>
        <p className="floor-plans-title">Floor Plans</p>
        <Slider {...settings}>
          <div
            className="floor-plan-slide"
            onClick={() => handleImageClick(floorPlan1)}
          >
            <img
              src={floorPlan1}
              alt="1BHK Floor Plan"
              className="floor-plan-image"
            />
          </div>
          <div
            className="floor-plan-slide"
            onClick={() => handleImageClick(floorPlan2)}
          >
            <img
              src={floorPlan2}
              alt="2BHK Floor Plan"
              className="floor-plan-image"
            />
            {/* ...existing code */}
          </div>
          <div
            className="floor-plan-slide"
            onClick={() => handleImageClick(floorPlan3)}
          >
            <img
              src={floorPlan3}
              alt="3BHK Floor Plan"
              className="floor-plan-image"
            />
            {/* ...existing code */}
          </div>
        </Slider>

        {selectedImage && (
          <div
            className="floor-plans-veda1-modal-overlay"
            onClick={handleCloseModal}
          >
            <div className="floor-plans-veda1-modal-content">
              <span
                className="floor-plans-veda1-close-btn"
                onClick={handleCloseModal}
              >
                ×
              </span>
              <img
                src={selectedImage}
                alt="Full Size"
                className={`floor-plans-veda1-modal-image ${
                  zoomed ? "zoomed" : ""
                }`}
                onClick={handleImageZoom}
              />
            </div>
          </div>
        )}
        <a
          className="download-brochure"
          target="_blank"
          href="https://drive.google.com/file/d/1SAxJRukjCJcCj5l1vuVCbTSU-9z8LLWk/view"
          download
        >
          Click Here to download Brochure
        </a>
        <div className="Project-location-container">
          <h1 className="floor-plans-project-location-title">
            PROJECT LOCATION
          </h1>
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.4333547378938!2d72.84477131092112!3d19.39367334184698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7af5eba5c0a49%3A0xc9218175bbe48!2sYashwant%20Smart%20City!5e0!3m2!1sen!2sin!4v1723270985949!5m2!1sen!2sin"
              style={{ border: "0", borderRadius: "0px 40px 0px 0px" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps Location"
            />
          </div>
        </div>
        <div className="rera-details-container">
          <h1 className="rera-details-title">RERA DETAILS</h1>
          <div>
            <p>
              MahaRERA Reg No: Nakshatra Veda I & II is registered with MahaRERA
              bearing RERA NO. P99000047826
            </p>

            <br />
            <p>
              For further details please visit: <span></span>
              <a
                href="http://maharera.mahaonline.gov.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://maharera.mahaonline.gov.in
              </a>
            </p>
            <p>*T&C Apply</p>
          </div>

          <div className="qr-codes">
            <img src={Veda_I} alt="QR Code Veda I" className="qr-code-image" />
            <img
              src={Veda_II}
              alt="QR Code Veda II"
              className="qr-code-image"
            />
          </div>
        </div>
        {/* <h1 className="enquiry-form-title-veda">ENQUIRY FORM</h1> */}
      </div>

      <div className="request-callback-form">
        <div>
          <h1>ENQUIRY FORM</h1>
        </div>
        <div className="callback-form-container">
          <div className="callback-logo">
            <img src={callImage} alt="Call Icon" />
          </div>
          <form className="callback-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name*"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <div className="error">{errors.name}</div>}
            <select
              name="project"
              value={formData.project}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Interested in which project
              </option>
              <option value="Nakshatra Veda Phase I">
                Nakshatra Veda Phase I
              </option>
              <option value="Nakshatra Veda Phase II">
                Nakshatra Veda Phase II
              </option>
              <option value="Nakshatra Aazstha">Nakshatra Aazstha</option>
            </select>
            {errors.project && <div className="error">{errors.project}</div>}
            <input
              type="text"
              placeholder="Contact Number*"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
            {errors.contactNumber && (
              <div className="error">{errors.contactNumber}</div>
            )}
            <input
              type="email"
              placeholder="Email ID*"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <div className="error">{errors.email}</div>}

            <button type="submit">REQUEST CALLBACK</button>
          </form>
        </div>
      </div>
    </>
  );
};

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="next-slide-arrow" onClick={onClick}>
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="prev-slide-arrow" onClick={onClick}>
      <img src={leftArrow} alt="Previous" />
    </div>
  );
};

export default FloorPlans;
