import React from "react";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import thumbsUp from '../images/thumbs-up.png';  // Ensure the image path is correct

const ThankYouPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formType = queryParams.get("formType");

  const handleButtonClick = () => {
    navigate("/");
  };

  let message;
  if (formType === "callback") {
    message = "We will get back to you shortly.";
  } else if (formType === "contact") {
    message = "Thank you for your interest!";
  } else {
    message = "Thank you for your submission!";
  }

  return (
    <div>
      <style>
        {`
          .thank-you-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .thank-you-content {
            text-align: center;
            font-size: 1.5rem;
            color: #414042;
            font-family: "Montserrat";
            background-color: #fff;
            padding: 20px;
            font-weight: 500;
            border: 2px solid #DCA52E;
          }

          .thank-you-ok-btn {
            margin-top: 20px;
            background-color: green;
            font-size: 1.2rem !important;
            color: white !important;
            font-weight: 500;
            font-family: "Montserrat" !important;
            background: var(--button-bg-color);
          }     
          .thank-you-ok-btn:hover{
              background: #414042 !important;
              color:#DCA52E !important;
          }

          .thank-you-image {
            margin: 20px 0px;
            max-width: 50px; /* Adjust the size as needed */
            height: 50px;
          }
        `}
      </style>
      <div className="thank-you-container">
        <div className="thank-you-content">
          <img src={thumbsUp} alt="Thumbs Up" className="thank-you-image" />
          <p>{message}</p>
          <Button
            variant="contained"
            className="thank-you-ok-btn"
            onClick={handleButtonClick}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
